/* BASIC SETUP */
@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 200;
  src: url(/fonts/segoeui/segoeui.woff) format("woff");
}
@font-face {
  font-family: 'metroSysIcons';
  src: url('/fonts/metroSysIcons/metroSysIcons.woff') format('woff'), url('/fonts/metroSysIcons/metroSysIcons.ttf') format('truetype'), url('/fonts/metroSysIcons/metroSysIcons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
}
html {
  font: 10px 'Segoe UI', Arial, Helvetica, sans-serif;
  /*background-color: #ddd;
	background-image: -moz-linear-gradient(center top, #aaa, #ddd);
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #aaa), color-stop(1, #ddd));
	background-image: linear-gradient(top, #aaa, #ddd);*/
  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#aaaaaa', EndColorStr='#dddddd');
  background-repeat: no-repeat;
  height: 100%;
  /* change the box model to exclude the padding from the calculation of 100% height (IE8+) */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  height: 0;
}
/* GENERAL */
.cleaner {
  clear: both;
}
/* MAIN LOGIN CONTAINER */
#main-login-container {
  position: relative;
  top: 200px;
  margin: 0 auto;
  width: 500px;
}
#main-login-container #encore-white-logo {
  position: absolute;
  left: 185px;
  top: -180px;
  width: 174px;
  height: 160px;
  background-position: -1px 0;
}
#main-login-container #login-form {
  background-color: rgba(59, 96, 130, 0.85);
  width: 500px;
  padding: 5px 15px 15px 15px;
}
#main-login-container #login-form #login-form-title {
  font-size: xx-large;
  color: white;
}
#main-login-container #login-form #error-message {
  color: red;
  margin: 0 0 5px 0;
  font-size: larger;
}
#main-login-container #login-form #credentials {
  margin: 0 0 10px 0;
}
#main-login-container #login-form #credentials #login-container {
  float: left;
  width: 250px;
}
#main-login-container #login-form #credentials #login-container label {
  display: block;
  color: white;
}
#main-login-container #login-form #credentials #login-container #login {
  width: 230px;
  height: 25px;
  padding: 5px;
  background-color: white;
  font-size: large;
  text-align: center;
}
#main-login-container #login-form #credentials #login-container #login:disabled {
  background-color: #bdbec0;
}
#main-login-container #login-form #credentials #pass-container {
  float: left;
  width: 250px;
}
#main-login-container #login-form #credentials #pass-container label {
  display: block;
  color: white;
}
#main-login-container #login-form #credentials #pass-container #pass {
  width: 230px;
  height: 25px;
  padding: 5px;
  background-color: white;
  font-size: large;
  text-align: center;
}
#main-login-container #login-form #credentials #pass-container #pass:disabled {
  background-color: #bdbec0;
}
#main-login-container #login-form #remember-me-container {
  color: white;
}
#main-login-container #login-form #login-btn {
  border: 1px solid white;
  background-color: #1f4e79;
  padding: 10px 23px;
  font-size: large;
  color: white;
  cursor: pointer;
  cursor: hand;
  position: relative;
  left: 365px;
}
.login-bottom {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 12px;
}
.login-bottom .login-links {
  display: flex;
  max-width: 340px;
  justify-content: space-between;
  font-size: 1.25rem;
  align-items: center;
  margin: 0 auto;
  padding-left: 65px;
}
.login-bottom .login-links a {
  color: #1f4e79;
}
.login-bottom .login-links i {
  font-size: 0.75rem;
}
/* GRAPH IN BACKGROUND */
#graph {
  position: absolute;
  z-index: -100;
}
#graph .node {
  stroke: white;
  stroke-width: 1.5px;
  cursor: pointer;
}
#graph .link {
  fill: none;
  stroke: black;
}
.encore-checkbox-container {
  font-size: 12px;
}
