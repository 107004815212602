.encore-checkbox-container {
  display: inline-block;
}
.encore-checkbox-container img {
  position: relative;
  top: 2px;
  cursor: pointer;
  cursor: hand;
}
.encore-checkbox-container .check {
  position: relative;
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border-radius: 1px;
  border: 1px #d9d9d9 solid;
  margin: 0 5px 0 0;
  vertical-align: middle;
  align-items: center;
  display: inline-flex;
  justify-content: center;
}
.encore-checkbox-container:not(.disabled) .check:hover {
  border-color: #919191;
  cursor: pointer;
}
.encore-checkbox-container.disabled .check {
  background-color: #d9d9d9;
}
.encore-checkbox-container .checked {
  position: relative;
}
.encore-checkbox-container .checked:after {
  content: "\E003";
  font-family: metroSysIcons;
  font-size: 1.14585rem;
  color: #1d1d1d;
}
.encore-checkbox-container .unknown-value {
  position: relative;
}
.encore-checkbox-container .unknown-value:after {
  content: "\E00C";
  font-family: metroSysIcons;
  font-size: 6pt;
  line-height: 5pt;
  color: #c5c5c5;
}
.encore-checkbox-container .half-checked {
  background-color: #d9d9d9 !important;
  position: relative;
}
.encore-checkbox-container .check-table {
  position: relative;
  top: 4px;
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border: 2px #d9d9d9 solid;
  padding: 0;
  margin: 0 5px 0 0;
  vertical-align: middle;
  align-items: center;
  display: inline-flex;
  justify-content: center;
}
.encore-checkbox-container .check-table:hover {
  border-color: #919191;
  cursor: pointer;
  cursor: hand;
}
.encore-checkbox-container .checked-table {
  position: relative;
}
.encore-checkbox-container .checked-table:after {
  content: "\E003";
  font-family: metroSysIcons;
  font-size: 9pt;
  color: #1d1d1d;
}
.encore-checkbox-container .disabled {
  position: relative;
  background-color: #e6e6e6;
}
.encore-checkbox-container .disabled:after {
  content: "\E003";
  font-family: metroSysIcons;
  font-size: 9pt;
  color: #8a8a8a;
}
.encore-checkbox-container .indeterminate {
  position: relative;
}
.encore-checkbox-container .indeterminate:after {
  display: block;
  content: "." !important;
  color: #d9d9d9;
  width: 10px;
  height: 10px;
  background-color: #1d1d1d;
  margin: 3px;
}
#remember-me-container .encore-checkbox-container .checked:after {
  left: 4px;
  top: 1px;
  font-size: 10pt;
  line-height: 10pt;
}
.encore-checkbox-container-small {
  display: inline-block;
}
.encore-checkbox-container-small img {
  position: relative;
  top: 2px;
  cursor: pointer;
  cursor: hand;
}
.encore-checkbox-container-small .check {
  position: relative;
  width: 14px;
  height: 14px;
  background-color: #ffffff;
  border: 2px #d9d9d9 solid;
  padding: 0;
  margin: 0 5px 0 0;
  vertical-align: middle;
  align-items: center;
  display: inline-flex;
  justify-content: center;
}
.encore-checkbox-container-small .check:hover {
  border-color: #919191;
  cursor: pointer;
  cursor: hand;
}
.encore-checkbox-container-small .checked {
  position: relative;
}
.encore-checkbox-container-small .checked:after {
  content: "\E003";
  font-family: metroSysIcons;
  font-size: 7pt;
  color: #1d1d1d;
}
.encore-checkbox-container-small .half-checked {
  background-color: #d9d9d9 !important;
  position: relative;
}
